import * as React from 'react';
import { css } from '@emotion/core';
import { Color, Margin, Breakpoint, PageWidth } from '../CSSConstants';
import Link from 'gatsby-link';
// @ts-ignore
import searchIcon from '../images/icon/search.svg';

interface Props {
  home?: boolean;
}

const Header = ({ home }: Props): JSX.Element => (
  <header css={styles.root}>
    <h1 css={styles.title}>
      <Link to="/">
        Tim<span className="light">Minch.in</span>
      </Link>
    </h1>
    <p css={styles.tagline}>
      Free Tim Minchin sheet music, chords, tabs, and videos
    </p>
    {!home && (
      <Link to="/" css={styles.search}>
        <img src={searchIcon} />
      </Link>
    )}
  </header>
);

const styles = {
  root: css`
    background: ${Color.white};
    margin: 0 auto ${Margin.default}px;
    max-width: ${PageWidth}px;
    padding: 1.6em 2em;
    position: relative;

    @media screen and (max-width: ${Breakpoint.small}px) {
      margin-bottom: ${Margin.small}px;
      padding: ${Margin.default}px;
    }

    @media print {
      padding: 0;

      &::after {
        clear: both;
        content: '';
        display: table;
      }
    }
  `,
  title: css`
    line-height: 1;
    margin: 0;
    font-size: 4.4rem;
    letter-spacing: 0.02em;

    @media screen and (max-width: ${Breakpoint.medium}px) {
      font-size: 3em;
    }

    @media screen and (max-width: ${Breakpoint.small}px) {
      font-size: 2.4em;
    }

    @media print {
      float: right;
      font-size: 1.6em;

      &::before {
        color: white;
        content: 'www.';
      }
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }

    .light {
      color: ${Color.darkGray};
    }
  `,
  tagline: css`
    font-size: 1.2em;
    line-height: 1;
    margin: 0.4em 0 0 0.4em;

    @media screen and (max-width: ${Breakpoint.medium}px) {
      font-size: 1em;
      margin-left: 0.3em;
    }

    @media screen and (max-width: ${Breakpoint.small}px) {
      font-size: 0.875em;
    }

    @media print {
      display: none;
    }
  `,
  search: css`
    display: none;
    height: 32px;
    opacity: 1;
    padding: 4px;
    width: 32px;

    position: absolute;
    top: ${Margin.small}px;
    right: ${Margin.small}px;

    @media screen and (max-width: ${Breakpoint.medium}px) {
      display: block;
    }
  `,
};

export default Header;
