import * as React from 'react';
import { css } from '@emotion/core';
import Link from 'gatsby-link';
import Footer from './Footer';
import GlobalStyles from './GlobalStyles';
import Header from './Header';
import Nav from './Nav';
import PageHead from './PageHead';
import { Color, Margin, Breakpoint, PageWidth } from '../CSSConstants';

interface Props {
  children: React.ReactNode;
  home?: boolean;
  title?: string;
}

const Layout = ({ children, home, title }: Props): JSX.Element => {
  const homeClass = home ? 'home' : null;

  return (
    <>
      <GlobalStyles />
      <PageHead title={title} />

      <Header home={home} />

      <main css={styles.main}>
        <Nav className={homeClass} css={styles.nav} />
        <section className={homeClass} css={styles.content}>
          {children}
        </section>
      </main>

      <Footer />
    </>
  );
};

// Styles

const styles = {
  main: css`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: ${PageWidth}px;

    @media screen and (max-width: ${Breakpoint.medium}px) {
      display: block;
    }
  `,
  nav: css`
    height: auto;
    min-width: 220px;
    margin-right: ${Margin.default}px;
    width: 300px;

    @media screen and (max-width: ${Breakpoint.medium}px) {
      margin-right: 0;
      margin-bottom: ${Margin.default}px;
      width: auto;

      &:not(.home) {
        display: none;
      }
    }

    @media print {
      display: none;
    }
  `,
  content: css`
    background: ${Color.lightGray};
    max-width: 800px;
    padding: ${Margin.small}px ${Margin.default}px;
    width: 100%;

    @media screen and (max-width: ${Breakpoint.medium}px) {
      &.home {
        display: none;
      }
    }

    @media screen and (max-width: ${Breakpoint.small}px) {
      padding: ${Margin.small}px;
    }

    @media print {
      background: none;
      padding: 0;

      h2 {
        margin-bottom: 0;
      }
    }

    > :first-child,
    > :first-child > :first-child {
      margin-top: 0;
    }

    > :last-child,
    > :last-child > :last-child {
      margin-bottom: 0;
    }
  `,
};

export default Layout;
