import * as React from 'react';
import Helmet from 'react-helmet';
// @ts-ignore
import favicon from '../images/favicon.ico';

interface Props {
  title?: string;
}

const PageHead = ({ title }: Props): JSX.Element => (
  <Helmet
    title={`${
      title != null ? `${title} - ` : ''
    }Tim Minchin Sheet Music and Chords`}
    meta={[
      {
        name: 'description',
        content:
          'Free Tim Minchin sheet music, chords, tabs and instructional videos for piano or guitar.',
      },
    ]}
  >
    {' '}
    <link rel="shortcut icon" href={favicon} />
  </Helmet>
);

export default PageHead;
