import * as React from 'react';
import { Global, css } from '@emotion/core';
// @ts-ignore
import bg from '../images/bg.jpg';
// @ts-ignore
import bgPattern from '../images/bg-pattern.gif';
import { Breakpoint, Margin } from '../CSSConstants';

const GlobalStyles = (): JSX.Element => (
  <Global
    styles={css`
      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }

      :root {
        background: url(${bg}) right bottom no-repeat fixed,
          url(${bgPattern}) left top repeat fixed;
        background-color: $color-black;
        background-size: auto 100%, auto;
        color: $color-black;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;

        @media screen and (max-width: ${Breakpoint.medium}) {
          background-size: 100% auto, auto;
        }

        @media screen and (max-width: ${Breakpoint.small}px) {
          font-size: 0.875em;
        }

        @media print {
          background: none;
        }
      }

      body {
        margin: ${Margin.default}px;

        @media screen and (max-width: ${Breakpoint.small}px) {
          margin: ${Margin.small}px;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'Oswald', sans-serif;
      }

      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 400;
      }

      h2 {
        font-size: 2.2em;
        margin-bottom: 0.4em;

        @media screen and (max-width: ${Breakpoint.small}px) {
          font-size: 1.6em;
        }
      }

      h3 {
        font-size: 1.8em;

        @media screen and (max-width: ${Breakpoint.small}px) {
          font-size: 1.2em;
        }
      }
    `}
  />
);

export default GlobalStyles;
