import * as React from 'react';
import { css } from '@emotion/core';

const Footer = (): JSX.Element => (
  <footer css={styles.root}>
    &copy;{' '}
    <a css={styles.link} href="https://berman.xyz" target="_blank">
      Mike Berman
    </a>
  </footer>
);

// Styles

const styles = {
  root: css`
    color: #888;
    text-align: center;
    margin-top: 1em;
  `,
  link: css`
    color: inherit;
    text-decoration: inherit;

    &:hover {
      text-decoration: underline;
    }
  `,
};

export default Footer;
