export const Breakpoint = {
  huge: 1599,
  large: 1279,
  medium: 767,
  small: 499,
};

export const Color = {
  white: 'white',
  lightGray: 'rgba(250,250,255,0.85)',
  lightGraySolid: '#d6d6db',
  midGray: '#9b9b9b',
  darkGray: '#666666',
  black: '#181818',
};

export const Margin = {
  default: 18,
  small: 10,
};

export const PageWidth = 1060;
