import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { navigate } from 'gatsby';
import Link from 'gatsby-link';
import { css } from '@emotion/core';
import { Color, Margin, Breakpoint } from '../CSSConstants';
import { Location } from '@reach/router';

const { useEffect, useState } = React;

interface Props {
  className?: string;
}

const Nav = ({ className }: Props): JSX.Element => {
  // Songs
  const data = useStaticQuery(graphql`
    query SongsQuery {
      allSongs(sort: { fields: [name], order: ASC }) {
        nodes {
          name
          slug
          album
        }
      }
    }
  `);

  const songs: Array<{ album?: string; name: string; slug: string }> =
    data.allSongs.nodes;

  // Filtered list
  const [filter, setFilter] = useState('');

  const normalizedFilter = normalizeString(filter);
  const filteredSongs =
    filter != ''
      ? songs.filter(
          song =>
            normalizeString(song.name).includes(normalizedFilter) ||
            (song.album &&
              normalizeString(song.album).includes(normalizedFilter)),
        )
      : songs;

  // Selection
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const max = filteredSongs.length - 1;
    switch (e.key) {
      case 'ArrowDown':
        setSelectedIndex(i => (i + 1 > max ? 0 : i + 1));
        break;
      case 'ArrowUp':
        setSelectedIndex(i => (i - 1 < 0 ? max : i - 1));
        break;
      case 'Enter':
        if (e.target instanceof HTMLElement) {
          e.target.blur();
        }
        const song = filteredSongs[selectedIndex];
        if (song) {
          navigate(`/${song.slug}/`);
        }
        break;
    }
  };

  useEffect(() => {
    setSelectedIndex(filteredSongs.length === 1 ? 0 : -1);
  }, [filter]);

  return (
    <nav className={className} css={styles.root}>
      <div css={styles.help}>Get started by selecting a song below</div>
      <input
        autoFocus
        css={styles.filter}
        onChange={e => setFilter(e.target.value)}
        onKeyDown={onKeyDown}
        type="search"
        placeholder="FILTER"
        value={filter}
      />

      <Location>
        {({ location }) =>
          filteredSongs.map((song, i) => (
            <Link
              to={`/${song.slug}/`}
              className={[
                location.pathname.startsWith(`/${song.slug}`)
                  ? 'current'
                  : null,
                i === selectedIndex ? 'selected' : null,
              ]
                .filter(Boolean)
                .join(' ')}
              css={styles.song}
              key={song.slug}
            >
              {song.name}
              {song.album != null && ` (${song.album})`}
            </Link>
          ))
        }
      </Location>
    </nav>
  );
};

// Filter

function normalizeString(str: string): string {
  return str.toLowerCase().replace(/[^a-z ]/gi, '');
}

// Styles

const styles = {
  root: css`
    background: ${Color.white};
    padding: 1em;
  `,
  help: css`
    display: none;

    @media screen and (max-width: ${Breakpoint.medium}px) {
      display: block;
      font-size: 1.2em;
      margin-bottom: ${Margin.default}px;
    }
  `,
  filter: css`
    border: 1px solid;
    font-family: inherit;
    font-size: inherit;
    margin-bottom: 1.6em;
    padding: 0.6em 0.8em;
    width: 100%;
  `,
  song: css`
    color: inherit;
    display: block;
    padding: 0.2em;
    text-decoration: inherit;

    @media screen and (max-width: ${Breakpoint.small}px) {
      font-size: 1.1em;
    }

    &:hover,
    &:active,
    &.selected {
      background: #ccc;
    }

    &.current {
      font-weight: bold;
    }
  `,
};

export default Nav;
